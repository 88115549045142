import { httpsCallable } from 'firebase/functions'
import { db, functions } from 'controllers/db'
import _ from 'lodash'
import { DBT } from 'types/internal'
import {
  collection,
  doc,
  onSnapshot,
  setDoc,
  updateDoc
} from 'firebase/firestore'

export const getLogs = async (interactionId: string) => {
  try {
    console.log('fetchLogs start')
    const callChatgpt = httpsCallable(functions, 'getTwoFactorLogs')
    const res = await callChatgpt({
      interactionId
    })
    console.log('callable function res:', res)
    return res.data
  } catch (e) {
    console.warn('error', e)
    return { error: _.get(e, 'message') }
  }
}

export const dbSubscribeToConf = (
  callback: (conf: DBT.AIPromptConfT) => void
) => {
  try {
    const ref = doc(collection(db, 'settings'), 'vegasConf')
    const unsubscribe = onSnapshot(
      ref,
      sn => {
        const flexConf = sn.data() as DBT.AIPromptConfT | undefined
        if (flexConf) {
          callback(flexConf)
        }
      },
      err => {
        console.log(`dbSubscribeToConf error: ${err.message}`)
      }
    )
    return unsubscribe
  } catch (e) {
    console.log('dbSubscribeToConf error', e)
    return null
  }
}

export const dbUpdateConf = async (conf: Partial<DBT.AIPromptConfT>) => {
  const ref = doc(collection(db, 'settings'), 'vegasConf')
  await setDoc(ref, conf)
}

export const dbSubscribeToUser = (
  userId: string,
  callback: (user: object) => void
) => {
  try {
    const ref = doc(collection(db, 'vegasUsers'), userId)
    const unsubscribe = onSnapshot(
      ref,
      sn => {
        const user = sn.data() as object | undefined
        if (user) {
          callback(user)
        }
      },
      err => {
        console.log(`subscribeToUser error: ${err.message}`)
      }
    )
    return unsubscribe
  } catch (e) {
    console.log('subscribeToUser error', e)
    return null
  }
}

export const dbSaveVegasUser = (user: Record<string, any>) => {
  const userId = _.get(user, 'id')
  const ref = doc(collection(db, 'vegasUsers'), userId)
  updateDoc(ref, user)
}

export const addVegasUserPhotos = async (userId: string, urls: string[]) => {
  try {
    console.log('addVegasUserPhotos start')
    const f = httpsCallable(functions, 'addVegasUserPhotos')
    const res = await f({
      userId,
      urls
    })
    console.log('callable function res:', res)
    return res.data
  } catch (e) {
    console.warn('error', e)
    return { error: _.get(e, 'message') }
  }
}

export const dbCreateVegasUser = (user: Record<string, any>) => {
  console.log('dbCreateVegasUser', user)
  const ref = doc(collection(db, 'vegasUsers'), user.id)
  setDoc(ref, user)
}

export const dbSearchVegasUserByPhoto = async (photo: string) => {
  try {
    console.log('dbSearchVegasUserByPhoto start')
    const f = httpsCallable(functions, 'searchVegasUserByPhoto')
    const res = await f({ photo })
    console.log('callable function res:', res)
    return res.data
  } catch (e) {
    console.warn('error', e)
    return { error: _.get(e, 'message') }
  }
}
