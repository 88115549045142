import { Stack, useToast, VStack, Image, Text, HStack } from '@chakra-ui/react'
import FilesPickerButton from 'components/FilesPickerButton'
import Label from 'components/Label'
import { generateId } from 'controllers/db'
import { FC, useMemo, useState } from 'react'
import { FileWithPath } from 'react-dropzone/.'
import { DBT } from 'types/internal'
import { saveFileObject } from 'controllers/storage'
import _ from 'lodash'
import { addVegasUserPhotos } from 'controllers/vegas'
import dayjs from 'dayjs'

type Props = {
  user: DBT.VegasUserT
}

const UserPhotos: FC<Props> = ({ user }) => {
  const [processingFiles, setProcessingFiles] = useState(false)
  const toast = useToast()

  const sortedFaces = useMemo(
    () => _.sortBy(user.faces, 'createdAt'),
    [user.faces]
  )

  const uploadFileToStorage = async (f: FileWithPath) => {
    if (user && user.id) {
      const fileExt = _.last(_.split(f.name, '.'))
      const filename = `${user.Id}_${generateId()}.${fileExt}`
      const storagePath = `vegasUsers/${filename}`
      // const storagePath = `tmp/${filename}`
      const storageFile = await saveFileObject(f, storagePath)
      return storageFile
    }
  }

  const onFilesPicked = async (files: FileWithPath[]) => {
    console.log('on files picked', files)
    if (files.length > 0 && user && user.id) {
      try {
        setProcessingFiles(true)
        const storageFiles = await Promise.all(
          _.map(files, uploadFileToStorage)
        )
        console.log('storageFiles', storageFiles)
        const urls = _.compact(_.map(storageFiles, sf => sf?.url))
        const res = await addVegasUserPhotos(user.id, urls)
        if (res && _.has(res, 'success') && res.success) {
          toast({
            title: 'Success',
            description: `${_.get(res, 'added', 0)} images successfully added`,
            status: 'success',
            duration: 3000,
            isClosable: true
          })
        } else {
          toast({
            title: 'Operation failed',
            description: _.get(res, 'message', ''),
            status: 'error',
            duration: 3000,
            isClosable: true
          })
        }
        setProcessingFiles(false)
      } catch (e) {
        console.error(e)
        setProcessingFiles(false)
        toast({
          title: 'Error',
          description: 'Failed to upload files, check console logs',
          status: 'error',
          duration: 3000,
          isClosable: true
        })
      }
    }
  }

  const renderFace = (face: DBT.FaceT) => {
    return (
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        w='full'
        spacing={{ base: 4, lg: 6 }}
      >
        <Image boxSize={40} objectFit={'contain'} src={face.url} />
        <VStack align={'start'}>
          <HStack>
            <Text color='zinc.600' fontSize='sm'>
              Created At:
            </Text>
            <Text color='zinc.600' fontSize='sm' fontWeight={'semibold'}>
              {dayjs(face.createdAt).format('lll')}
            </Text>
          </HStack>
          <HStack>
            <Text color='zinc.600' fontSize='sm'>
              Face ID:
            </Text>
            <Text color='zinc.600' fontSize='sm' fontWeight={'semibold'}>
              {face.id}
            </Text>
          </HStack>
        </VStack>
      </Stack>
    )
  }

  return (
    <VStack pt={6} w='full' align={'start'}>
      <Label>Photos:</Label>
      <FilesPickerButton
        options={{
          accept: { 'image/jpeg': [], 'image/png': [], 'image/heic': [] }
        }}
        onFilesPicked={onFilesPicked}
        isLoading={processingFiles}
      />
      <VStack w='full' pt={6}>
        {_.map(sortedFaces, renderFace)}
      </VStack>
      {/* <Button onClick={fixPhotos}>Fix</Button> */}
    </VStack>
  )
}

export default UserPhotos
