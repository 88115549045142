import {
  Button,
  HStack,
  IconButton,
  Image,
  Input,
  Text,
  VStack
} from '@chakra-ui/react'
import NavBar from 'components/NavBar'
import UsersSearch from 'components/UsersSearch'
import { dbSaveVegasUser, dbSubscribeToUser } from 'controllers/vegas'
import { Unsubscribe } from 'firebase/firestore'
import { useState, useRef, useEffect } from 'react'
import _ from 'lodash'
import { DeleteIcon } from '@chakra-ui/icons'

import AddVegasUserModal, { IAddVegasUserModal } from 'modals/AddVegasUserModal'
import SearchByPhoto from 'pages/vegasUsers/SearchByPhoto'
import { isDev } from 'src/config'
import UserPhotos from 'pages/vegasUsers/UserPhotos'
import { DBT } from 'types/internal'

const VegasUsers = () => {
  const [userId, setUserId] = useState<string>()
  const [user, setUser] = useState<DBT.VegasUserT>()
  const [savedUser, setSavedUser] = useState<object>()
  const unsubscribeRef = useRef<Unsubscribe | null>(null)
  const [newKey, setNewKey] = useState('')
  const [newValue, setNewValue] = useState('')
  const addVegasUserModalRef = useRef<IAddVegasUserModal>(null)

  useEffect(() => {
    if (unsubscribeRef.current) {
      unsubscribeRef.current()
    }
    if (userId) {
      unsubscribeRef.current = dbSubscribeToUser(userId, u => {
        setUser(u as DBT.VegasUserT)
        setSavedUser(u)
      })
    }
  }, [userId])

  console.log('user', user)

  const onSelectUser = (userId: string) => {
    console.log('onSelectUser', userId)
    setUserId(userId)
  }

  const renderSearch = () => {
    return (
      <VStack p={4} w='full' align={'start'} spacing={6}>
        <UsersSearch onSelect={onSelectUser} />
        <SearchByPhoto onUserFound={onSelectUser} />
      </VStack>
    )
  }

  const handleEdit = (key: string, newValue: string) => {
    setUser({ ...user, [key]: newValue } as DBT.VegasUserT)
  }

  const handleDelete = (key: string) => {
    const newValue = { ...user }
    delete newValue[key]
    setUser(newValue as DBT.VegasUserT)
  }

  const handleAdd = () => {
    if (
      newKey &&
      newValue &&
      newKey !== 'id' &&
      newKey !== 'photoUrl' &&
      newKey !== 'photos' &&
      newKey !== 'faces'
    ) {
      setUser({ ...user, [_.camelCase(newKey)]: newValue } as DBT.VegasUserT)
      setNewKey('')
      setNewValue('')
    }
  }

  const renderUserParams = () => {
    if (user) {
      const omitedUser = _.omit(user, ['photoUrl', 'photos', 'faces'])
      const sortedKeys = _.keys(omitedUser).sort()
      return (
        <VStack w='full' align={'start'}>
          {user.photoUrl && (
            <Image
              boxSize='100px'
              objectFit='cover'
              src={user.photoUrl}
              alt={user.name}
            />
          )}
          <Text color='zinc.800' fontWeight={'semibold'}>
            {user.name}
          </Text>
          {_.map(sortedKeys, key => {
            const val = user[key]
            return (
              <VStack key={key} align='stretch' w='full'>
                <HStack align={'start'}>
                  <Input
                    value={key}
                    isReadOnly
                    size='sm'
                    flex={1}
                    flexShrink={0}
                    isDisabled={key === 'id'}
                  />
                  <Input
                    value={val}
                    size='sm'
                    onChange={e => handleEdit(key, e.target.value)}
                    flex={2}
                    isDisabled={key === 'id'}
                  />
                  <IconButton
                    w={{ base: 4, lg: '53px' }}
                    aria-label='Delete'
                    variant={'ghost'}
                    icon={<DeleteIcon />}
                    onClick={() => handleDelete(key)}
                    size={{ base: 'xs', lg: 'sm' }}
                    isDisabled={key === 'id'}
                  />
                </HStack>
              </VStack>
            )
          })}
        </VStack>
      )
    }
  }

  const renderAddUserParam = () => {
    if (user) {
      return (
        <HStack w='full'>
          <Input
            placeholder='New key'
            value={newKey}
            onChange={e => setNewKey(e.target.value)}
            size='sm'
            flex={1}
            flexShrink={0}
          />
          <Input
            placeholder='New value'
            value={newValue}
            onChange={e => setNewValue(e.target.value)}
            size='sm'
            flex={2}
            flexShrink={0}
          />
          <Button size='sm' onClick={handleAdd} variant={'primary'}>
            Add
          </Button>
        </HStack>
      )
    }
  }

  const onSave = () => {
    if (user) {
      dbSaveVegasUser(user)
    }
  }

  const onUserCreated = (userId: string) => {
    setUserId(userId)
  }

  const renderPhotos = () => {
    if (user) {
      return <UserPhotos user={user} />
    }
  }

  const actions = (
    <HStack>
      <Button
        variant={'primary'}
        size='sm'
        onClick={() => addVegasUserModalRef.current?.open()}
      >
        Add user
      </Button>
      <Button
        variant={'outline'}
        colorScheme='white'
        onClick={onSave}
        isDisabled={_.isEqual(user, savedUser)}
        size='sm'
      >
        Save
      </Button>
    </HStack>
  )

  if (isDev) {
    return (
      <VStack
        w='full'
        h='full'
        bg='white'
        overflow={'hidden'}
        pt={16}
        spacing={6}
      >
        <Text>Managing users is available in production environment only</Text>
        <Button
          variant='primary'
          onClick={() =>
            window.open('https://admin.facesign.ai/vegas_users', '_blank')
          }
        >
          Open prod admin panel
        </Button>
      </VStack>
    )
  } else {
    return (
      <VStack w='full' h='full' bg='white' overflow={'hidden'}>
        <NavBar title={'Vegas users'} actions={actions} />
        {renderSearch()}
        <VStack
          w='full'
          overflow={'auto'}
          p={{ base: 2, lg: 6 }}
          h='full'
          flex={1}
        >
          {/* <VStack w='full' spacing={10} flex={1}> */}
          {renderUserParams()}
          {renderAddUserParam()}
          {renderPhotos()}
          {/* </VStack> */}
        </VStack>
        <AddVegasUserModal
          ref={addVegasUserModalRef}
          onUserCreated={onUserCreated}
        />
      </VStack>
    )
  }
}

export default VegasUsers
